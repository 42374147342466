<template>
  <div>
    <v-card style="padding: 10px">
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <label for="product_name">{{ translate('Machine Name') }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field id="product_name" v-model="product_name" outlined dense class="shrink"
              :placeholder="translate('Machine Name')" hide-details></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="12">
            <v-btn color="primary" v-on:click="submit"> {{ translate('Submit') }} </v-btn>
            <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined> {{ translate('Cancel') }} </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { newCategory, newMachine, newMeasurement } from '@/ApiManager'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  methods: {
    cancel: function (_) {
      this.$router.go(-1)
    },
    submit: function (_) {
      newMachine(this.product_name).then(response => {
        this.$router.push({ name: 'machines' })
      })
    },
  },
  setup() {
    const product_name = ref('')

    return {
      product_name,
    }
  },
}
</script>
<style>
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');
</style>